<template>
  <div>
    <div class="news_info">
      <div class="news_info_title">
        <p>新闻资讯<span>NEWS</span></p>
        <span
          class="seeMore"
          @click="jump('/newsUpdates?routeType=2&tabIsShow=true&tabType=xwzx')"
          >查看更多</span
        >
      </div>
      <div class="center">
        <div class="news_info_sw">
          <dl>
            <dt>
              <img
                src="@/assets/img/tourismManagementImg/企业微信截图_1699434203479.png"
                alt=""
              />
            </dt>
            <dd>
              <p>2022-09-20</p>
              <p>
                加强文化贸易理论研究，推动对外文化贸易高质量发展专题调研座谈会在北二外成功举行！
              </p>
            </dd>
          </dl>
          <ul>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
        </div>
        <div class="news_info_list">
          <dl>
            <dt>
              <p>08</p>
              <p>2022.08</p>
            </dt>
            <dd>
              <p style="color: red">
                李嘉珊副院长 李嘉珊副院长李嘉珊副院长《助力文化强国建设
                推进文化贸易高质量发展》文章刊登光明日报推进文化贸易高质量发展》文章刊登光明日报
              </p>
            </dd>
          </dl>
          <dl>
            <dt>
              <p>08</p>
              <p>2022.08</p>
            </dt>
            <dd>
              <p>
                李嘉珊副院长 《助力文化强国建设
                推进文化贸易高质量发展》文章刊登光明日报
              </p>
            </dd>
          </dl>
          <dl>
            <dt>
              <p>08</p>
              <p>2022.08</p>
            </dt>
            <dd>
              <p>
                李嘉珊副院长 《助力文化强国建设
                推进文化贸易高质量发展》文章刊登光明日报
              </p>
            </dd>
          </dl>
          <dl>
            <dt>
              <p>08</p>
              <p>2022.08</p>
            </dt>
            <dd>
              <p>
                李嘉珊副院长 《助力文化强国建设
                推进文化贸易高质量发展》文章刊登光明日报
              </p>
            </dd>
          </dl>
        </div>
      </div>
    </div>
    <div class="notice">
      <div class="notice_title">
        <p>通知公告<span>ANNOUNCEMENT</span></p>
        <span
          class="seeMore"
          @click="jump('/noticeAndAnnouncement?routeType=2&tabIsShow=true&tabType=tzgg')"
          >查看更多</span
        >
      </div>
      <div class="notice_module">
        <dl>
          <dt>
            <p>02/20</p>
            <p>2020</p>
          </dt>
          <dd>旅科苑召开全体惯培生返校前教育暨疫情防控</dd>
        </dl>
        <dl>
          <dt>
            <p>02/20</p>
            <p>2020</p>
          </dt>
          <dd>旅科苑召开全体惯培生返校前教育暨疫情防控</dd>
        </dl>
        <dl>
          <dt>
            <p>02/20</p>
            <p>2020</p>
          </dt>
          <dd>旅科苑召开全体惯培生返校前教育暨疫情防控</dd>
        </dl>
        <dl style="background: rgba(212, 13, 13, 0.842); color: #fff">
          <dt>
            <p>02/20</p>
            <p>2020</p>
          </dt>
          <dd>旅科苑召开全体惯培生返校前教育暨疫情防控</dd>
        </dl>
      </div>
    </div>
    <div class="science">
      <div class="science_title">
        <p>科学研究<span>SCIENTIFIC RESEARCH</span></p>
        <span
          class="seeMore"
          @click="jump('/nationalLevel?routeType=2&tabIsShow=true&tabType=gjj')"
          >查看更多</span
        >
      </div>
      <div class="science_piece" style="background: rgba(184, 16, 16, 0.842)">
        <p>国<br />家<br />级</p>
        <p>NATIONAL LEVEL</p>
      </div>
      <div class="science_piece" style="background: #047baa">
        <p>省<br />部<br />级</p>
        <p style="margin-top: 18.48958vw; margin-left: -1.30208vw">PROVINCIAL</p>
      </div>
      <div class="science_piece" style="background: #092f52">
        <p>地<br />厅<br />级</p>
        <p>BASEMENT LEVEL</p>
      </div>
      <div class="science_piece" style="background: #0a293d">
        <p>重<br />点<br />专<br />项<br />研<br />究<br />任<br />务</p>
        <p style="margin-top: 22.13542vw; margin-left: -4.6875vw">
          KEY SPECIAL RESEARCH TASKS
        </p>
      </div>
    </div>
    <div class="media">
      <div class="media_title">
        <p>媒体报道<span>MEDIA COVERAGE</span></p>
        <span
          class="seeMore"
          @click="jump('/mediaCoverage?routeType=2&tabIsShow=true&tabType=mtbd')"
          >查看更多</span
        >
      </div>
      <ul>
        <li>
          <p>【首都经济报道】</p>
          <p>
            <span>推动北京率先实现服务贸易高质量发展</span>
            <span class="toRight">2022-09-20</span>
          </p>
        </li>
        <li>
          <p>【首都经济报道】</p>
          <p>
            <span>推动北京率先实现服务贸易高质量发展</span>
            <span class="toRight">2022-09-20</span>
          </p>
        </li>
        <li>
          <p>【首都经济报道】</p>
          <p>
            <span>推动北京率先实现服务贸易高质量发展</span>
            <span class="toRight">2022-09-20</span>
          </p>
        </li>
        <li>
          <p>【首都经济报道】</p>
          <p>
            <span>推动北京率先实现服务贸易高质量发展</span>
            <span class="toRight">2022-09-20</span>
          </p>
        </li>
        <li>
          <p>【首都经济报道】</p>
          <p>
            <span>推动北京率先实现服务贸易高质量发展</span>
            <span class="toRight">2022-09-20</span>
          </p>
        </li>
        <li>
          <p>【首都经济报道】</p>
          <p>
            <span>推动北京率先实现服务贸易高质量发展</span>
            <span class="toRight">2022-09-20</span>
          </p>
        </li>
        <li>
          <p>【首都经济报道】</p>
          <p>
            <span>推动北京率先实现服务贸易高质量发展</span>
            <span class="toRight">2022-09-20</span>
          </p>
        </li>
        <li>
          <p>【首都经济报道】</p>
          <p>
            <span>推动北京率先实现服务贸易高质量发展</span>
            <span class="toRight">2022-09-20</span>
          </p>
        </li>
      </ul>
      <img
        class="city"
        src="@/assets/img/tourismManagementImg/1699427795970.jpg"
        alt=""
      />
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    jump(path) {
      this.$router.push({
        path,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.news_info {
  width: 100%;
  height: 700px;
  background: #e7e5dd;
  padding-top: 20px;
}
.news_info_title {
  height: 100px;
  line-height: 100px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.news_info_title p {
  width: 200px;
  font-size: 30px;
  margin-left: 100px;
}
.news_info_title p span {
  font-size: 16px;
  color: #999;
  margin-left: 10px;
}
.seeMore {
  font-size: 16px;
  color: #999;
  margin-right: 100px;
  padding-top: 6px;
  cursor: pointer;
}
.news_info .center {
  width: 100%;
  display: flex;
}
.news_info_sw {
  width: 42%;
  height: 500px;
  margin-left: 100px;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.5);
  background: #fff;
  position: relative;
}
.news_info_sw dl {
  width: 100%;
  height: 100%;
}
.news_info_sw dl dt {
  height: 300px;
}
.news_info_sw img {
  width: 100%;
  height: 100%;
}
.news_info_sw dl dd {
  padding: 0 20px;
}
.news_info_sw dl dd p {
  margin-top: 20px;
}
.news_info_sw ul {
  width: 100%;
  list-style: none;
  position: absolute;
  bottom: 20px;
  left: 42%;
}
.news_info_sw ul li {
  float: left;
  width: 10px;
  height: 10px;
  background: #000;
  margin-left: 10px;
  border-radius: 50%;
}
.news_info_list {
  height: 500px;
  margin-left: 70px;
  width: 40%;
  margin-right: 100px;
}
.news_info_list dl {
  height: 100px;
  width: 100%;
  display: flex;
  border-bottom: 1px solid #fff;
  padding-top: 25px;
}
.news_info_list dl dt {
  width: 80px;
  height: 70px;
  border: 1px solid red;
  border-radius: 10px;
  margin-right: 10px;
  padding-top: 10px;
}
.news_info_list dt p:first-child {
  font-size: 25px;
  text-align: center;
}
.news_info_list dt p:nth-child(2) {
  text-align: center;
  width: 80px;
}
.news_info_list dl dd {
  padding-top: 10px;
}
.news_info_list dl dd p {
  width: 600px;
  line-height: 30px;
}
/* --------------------- */
.notice {
  width: 100%;
  height: 450px;
  background: #eeeeed;
  padding-top: 20px;
}
.notice_title {
  height: 100px;
  line-height: 100px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.notice_title p {
  width: 300px;
  font-size: 30px;
  margin-left: 100px;
}
.notice_title p span {
  font-size: 16px;
  color: #999;
  margin-left: 10px;
}
.notice_module {
  margin-left: 100px;
  display: flex;
  margin-right: 100px;
  justify-content: space-between;
}
.notice_module dl {
  width: 400px;
  height: 250px;
  background: #fff;
}
.notice_module dl dt {
  margin: 50px 0 0 40px;
}
.notice_module dl dt p:first-child {
  font-size: 30px;
}
.notice_module dl dd {
  margin: 50px 40px 0 40px;
}
/* ------------------------ */
.science {
  width: 100%;
  height: 800px;
  background: #eeeeed;
  padding-top: 20px;
  position: relative;
  color: #fff;
}
.science_title {
  height: 100px;
  line-height: 100px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: absolute;
  left: 0;
  top: 30px;
}
.science_title p {
  width: 400px;
  font-size: 30px;
  margin-left: 100px;
}
.science_title p span {
  font-size: 16px;
  color: #999;
  margin-left: 10px;
}
.science_piece {
  width: 25%;
  height: 100%;
  float: left;
}
.science_piece p:first-child {
  width: 50%;
  text-align: right;
  font-size: 40px;
  padding-top: 310px;
  float: left;
}
.science_piece p:nth-child(2) {
  text-align: left;
  float: left;
  transform: rotate(90deg);
  margin-top: 375px;
  margin-left: -40px;
  color: #999;
}
/* ----------------------------------- */
.media {
  width: 100%;
  height: 900px;
  background: #eeeeed;
  padding-top: 20px;
  position: relative;
}
.media_title {
  height: 100px;
  line-height: 100px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.media_title p {
  width: 400px;
  font-size: 30px;
  margin-left: 100px;
}
.media_title p span {
  font-size: 16px;
  color: #999;
  margin-left: 10px;
}
.media ul {
  list-style: none;
  margin-left: 100px;
  margin-right: 100px;
  float: left;
}
.media ul li {
  width: 49%;
  float: left;
  height: 130px;
  background: #fff;
  border-radius: 5px;
  margin: 0 10px 10px 0;
}
.media ul li:nth-child(1) {
  background: rgba(184, 16, 16, 0.842);
  color: #fff;
}
.media ul li p:first-child {
  margin: 24px 0 30px 24px;
}
.media ul li p:nth-child(2) {
  margin: 24px 0 0 24px;
  position: relative;
}
.toRight {
  position: absolute;
  right: 24px;
}
.city {
  height: 230px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}
/* ------------------------- */
</style>
