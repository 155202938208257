var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"news_info"},[_c('div',{staticClass:"news_info_title"},[_vm._m(0),_c('span',{staticClass:"seeMore",on:{"click":function($event){return _vm.jump('/newsUpdates?routeType=2&tabIsShow=true&tabType=xwzx')}}},[_vm._v("查看更多")])]),_vm._m(1)]),_c('div',{staticClass:"notice"},[_c('div',{staticClass:"notice_title"},[_vm._m(2),_c('span',{staticClass:"seeMore",on:{"click":function($event){return _vm.jump('/noticeAndAnnouncement?routeType=2&tabIsShow=true&tabType=tzgg')}}},[_vm._v("查看更多")])]),_vm._m(3)]),_c('div',{staticClass:"science"},[_c('div',{staticClass:"science_title"},[_vm._m(4),_c('span',{staticClass:"seeMore",on:{"click":function($event){return _vm.jump('/nationalLevel?routeType=2&tabIsShow=true&tabType=gjj')}}},[_vm._v("查看更多")])]),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8)]),_c('div',{staticClass:"media"},[_c('div',{staticClass:"media_title"},[_vm._m(9),_c('span',{staticClass:"seeMore",on:{"click":function($event){return _vm.jump('/mediaCoverage?routeType=2&tabIsShow=true&tabType=mtbd')}}},[_vm._v("查看更多")])]),_vm._m(10),_c('img',{staticClass:"city",attrs:{"src":require("@/assets/img/tourismManagementImg/1699427795970.jpg"),"alt":""}})])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("新闻资讯"),_c('span',[_vm._v("NEWS")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"center"},[_c('div',{staticClass:"news_info_sw"},[_c('dl',[_c('dt',[_c('img',{attrs:{"src":require("@/assets/img/tourismManagementImg/企业微信截图_1699434203479.png"),"alt":""}})]),_c('dd',[_c('p',[_vm._v("2022-09-20")]),_c('p',[_vm._v(" 加强文化贸易理论研究，推动对外文化贸易高质量发展专题调研座谈会在北二外成功举行！ ")])])]),_c('ul',[_c('li'),_c('li'),_c('li'),_c('li')])]),_c('div',{staticClass:"news_info_list"},[_c('dl',[_c('dt',[_c('p',[_vm._v("08")]),_c('p',[_vm._v("2022.08")])]),_c('dd',[_c('p',{staticStyle:{"color":"red"}},[_vm._v(" 李嘉珊副院长 李嘉珊副院长李嘉珊副院长《助力文化强国建设 推进文化贸易高质量发展》文章刊登光明日报推进文化贸易高质量发展》文章刊登光明日报 ")])])]),_c('dl',[_c('dt',[_c('p',[_vm._v("08")]),_c('p',[_vm._v("2022.08")])]),_c('dd',[_c('p',[_vm._v(" 李嘉珊副院长 《助力文化强国建设 推进文化贸易高质量发展》文章刊登光明日报 ")])])]),_c('dl',[_c('dt',[_c('p',[_vm._v("08")]),_c('p',[_vm._v("2022.08")])]),_c('dd',[_c('p',[_vm._v(" 李嘉珊副院长 《助力文化强国建设 推进文化贸易高质量发展》文章刊登光明日报 ")])])]),_c('dl',[_c('dt',[_c('p',[_vm._v("08")]),_c('p',[_vm._v("2022.08")])]),_c('dd',[_c('p',[_vm._v(" 李嘉珊副院长 《助力文化强国建设 推进文化贸易高质量发展》文章刊登光明日报 ")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("通知公告"),_c('span',[_vm._v("ANNOUNCEMENT")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"notice_module"},[_c('dl',[_c('dt',[_c('p',[_vm._v("02/20")]),_c('p',[_vm._v("2020")])]),_c('dd',[_vm._v("旅科苑召开全体惯培生返校前教育暨疫情防控")])]),_c('dl',[_c('dt',[_c('p',[_vm._v("02/20")]),_c('p',[_vm._v("2020")])]),_c('dd',[_vm._v("旅科苑召开全体惯培生返校前教育暨疫情防控")])]),_c('dl',[_c('dt',[_c('p',[_vm._v("02/20")]),_c('p',[_vm._v("2020")])]),_c('dd',[_vm._v("旅科苑召开全体惯培生返校前教育暨疫情防控")])]),_c('dl',{staticStyle:{"background":"rgba(212, 13, 13, 0.842)","color":"#fff"}},[_c('dt',[_c('p',[_vm._v("02/20")]),_c('p',[_vm._v("2020")])]),_c('dd',[_vm._v("旅科苑召开全体惯培生返校前教育暨疫情防控")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("科学研究"),_c('span',[_vm._v("SCIENTIFIC RESEARCH")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"science_piece",staticStyle:{"background":"rgba(184, 16, 16, 0.842)"}},[_c('p',[_vm._v("国"),_c('br'),_vm._v("家"),_c('br'),_vm._v("级")]),_c('p',[_vm._v("NATIONAL LEVEL")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"science_piece",staticStyle:{"background":"#047baa"}},[_c('p',[_vm._v("省"),_c('br'),_vm._v("部"),_c('br'),_vm._v("级")]),_c('p',{staticStyle:{"margin-top":"18.48958vw","margin-left":"-1.30208vw"}},[_vm._v("PROVINCIAL")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"science_piece",staticStyle:{"background":"#092f52"}},[_c('p',[_vm._v("地"),_c('br'),_vm._v("厅"),_c('br'),_vm._v("级")]),_c('p',[_vm._v("BASEMENT LEVEL")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"science_piece",staticStyle:{"background":"#0a293d"}},[_c('p',[_vm._v("重"),_c('br'),_vm._v("点"),_c('br'),_vm._v("专"),_c('br'),_vm._v("项"),_c('br'),_vm._v("研"),_c('br'),_vm._v("究"),_c('br'),_vm._v("任"),_c('br'),_vm._v("务")]),_c('p',{staticStyle:{"margin-top":"22.13542vw","margin-left":"-4.6875vw"}},[_vm._v(" KEY SPECIAL RESEARCH TASKS ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("媒体报道"),_c('span',[_vm._v("MEDIA COVERAGE")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_c('p',[_vm._v("【首都经济报道】")]),_c('p',[_c('span',[_vm._v("推动北京率先实现服务贸易高质量发展")]),_c('span',{staticClass:"toRight"},[_vm._v("2022-09-20")])])]),_c('li',[_c('p',[_vm._v("【首都经济报道】")]),_c('p',[_c('span',[_vm._v("推动北京率先实现服务贸易高质量发展")]),_c('span',{staticClass:"toRight"},[_vm._v("2022-09-20")])])]),_c('li',[_c('p',[_vm._v("【首都经济报道】")]),_c('p',[_c('span',[_vm._v("推动北京率先实现服务贸易高质量发展")]),_c('span',{staticClass:"toRight"},[_vm._v("2022-09-20")])])]),_c('li',[_c('p',[_vm._v("【首都经济报道】")]),_c('p',[_c('span',[_vm._v("推动北京率先实现服务贸易高质量发展")]),_c('span',{staticClass:"toRight"},[_vm._v("2022-09-20")])])]),_c('li',[_c('p',[_vm._v("【首都经济报道】")]),_c('p',[_c('span',[_vm._v("推动北京率先实现服务贸易高质量发展")]),_c('span',{staticClass:"toRight"},[_vm._v("2022-09-20")])])]),_c('li',[_c('p',[_vm._v("【首都经济报道】")]),_c('p',[_c('span',[_vm._v("推动北京率先实现服务贸易高质量发展")]),_c('span',{staticClass:"toRight"},[_vm._v("2022-09-20")])])]),_c('li',[_c('p',[_vm._v("【首都经济报道】")]),_c('p',[_c('span',[_vm._v("推动北京率先实现服务贸易高质量发展")]),_c('span',{staticClass:"toRight"},[_vm._v("2022-09-20")])])]),_c('li',[_c('p',[_vm._v("【首都经济报道】")]),_c('p',[_c('span',[_vm._v("推动北京率先实现服务贸易高质量发展")]),_c('span',{staticClass:"toRight"},[_vm._v("2022-09-20")])])])])
}]

export { render, staticRenderFns }